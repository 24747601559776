const menuButton = $('#menu')
const menu = $('.menu')
const menuOverlay = $('.menu__overlay')
const menuClose = $('.menu__close')

function handleMenuClick() {
  menu.toggleClass('menu--open')
  menuOverlay.toggleClass('menu__overlay--open')
}

menuButton.click(handleMenuClick)
menuOverlay.click(handleMenuClick)
menuClose.click(handleMenuClick)


let rentalSwitch = document.querySelector('.rentalToggle');
let realSwitch = document.querySelector('.realToggle');
let bFiltersOne = document.querySelector('.b-filters');
let bFiltersTwo = document.querySelector('.b-filters2');

if (rentalSwitch) {
  realSwitch.addEventListener('click', function(){
    realSwitch.style.borderBottom = '1px solid #396186';
    rentalSwitch.style.borderBottom = '0px solid #396186';
    bFiltersOne.style.display = 'block';
    bFiltersTwo.style.display = 'none';
  });

  rentalSwitch.addEventListener('click',function(){
    realSwitch.style.borderBottom = '0px solid #396186';
    rentalSwitch.style.borderBottom = '1px solid #396186';
    bFiltersOne.style.display = 'none';
    bFiltersTwo.style.display = 'block';
  });
}
$(function () {
  $('input[name="daterange"]').daterangepicker({
    opens: 'left',
    autoUpdateInput: false,
  }, function (start, end, label) {
    console.log("A new date selection was made: " + start.format('YYYY-MM-DD') + ' to ' + end.format('YYYY-MM-DD'));
  });
});
