let huicalendar = function(element, options) {
    this.element = element;
    this.enabledDay = options.enabledDay;
    this.viewDay = options.viewDay || new Date();
    this.year = this.viewDay.getFullYear();
    this.month = this.viewDay.getMonth();
    this.monthArr = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    this.getCalendar();
    this.bindEvent();
}
huicalendar.prototype = {
    getCalendar: function(){
        let that = this;

        let isLeapYear = (((this.year % 4 === 0) && (this.year % 100 !== 0)) || (this.year % 400 === 0));
        let daysInMonth = [31, (isLeapYear ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][this.month]

        let dateFirst = new Date(this.year+'/'+(this.month+1)+'/'+1);
        let dayFirst = dateFirst.getDay();
        if (dayFirst === 0) dayFirst = 7;

        let calendar = [];
        for (let i = 1; i < dayFirst; i++) {
            calendar.push('');
        };
        for (let i = 1; i <= daysInMonth; i++) {
            calendar.push(i);
        };
        while (calendar.length % 7 !== 0) {
            calendar.push('');
        };

        let tbody = '';
        for (let i = 0; i < calendar.length; i++) {
            if(i%7 == 0){
                tbody += '<tr> <td class="'+( ($.inArray(calendar[i], this.enabledDay) == -1)? '':'enabled' )+'">'+calendar[i]+'</td>';
            } else if(i%7 == 6){
                tbody += '<td class="'+( ($.inArray(calendar[i], this.enabledDay) == -1)? '':'enabled' )+'">'+calendar[i]+'</td> </tr>';
            } else {
                tbody += '<td class="'+( ($.inArray(calendar[i], this.enabledDay) == -1)? '':'enabled' )+'">'+calendar[i]+'</td>';
            }
        };

        let monthTab = '<ul>';
        for (let i = 0; i < this.monthArr.length; i++) {
            monthTab += '<li value="'+i+'" class="'+(i==1? 'border-top1':'')+'">'+this.monthArr[i]+'</li>';
        };
        monthTab += '</ul>';

        let thead =
            '<thead>'+
            '<tr>'+
            '<th colspan="2" class="month" month="'+(this.month+1)+'">'+this.monthArr[this.month]+monthTab+'</th>'+
            '<th colspan="3" class="year" year="'+this.year+'">'+this.year+'</th>'+
            '</tr>'+
            '<tr class="week">'+
            '<th>M</th>'+
            '<th>T</th>'+
            '<th>W</th>'+
            '<th>T</th>'+
            '<th>F</th>'+
            '<th>S</th>'+
            '<th>S</th>'+
            '</tr>'+
            '</thead>';
        let table = '<table class="huicalendar huicalendar1 huicalendar2" cellspacing="0">'+thead+'<tbody>'+tbody+'</tbody></table>';
        this.element.html(table);
    },
    bindEvent: function(){
        let that = this;
        this.element.on('click', '.left', function(){
            that.month--;
            if(that.month == -1){
                that.month = 11;
                that.year--;
                if(that.year == 1900){
                    that.year++;
                    that.month++;
                }
            }
            that.getCalendar();
            that.element.trigger({
                type: 'changeMonth',
                month: parseInt(that.month)+1,
                year: parseInt(that.year)
            })
        })
        this.element.on('click', '.right', function(){
            that.month++;
            if(that.month == 12){
                that.month = 0;
                that.year++;
            }
            that.getCalendar();
            that.element.trigger({
                type: 'changeMonth',
                month: parseInt(that.month)+1,
                year: parseInt(that.year)
            })
        })
        this.element.on('click', '.month', function(){
            $(this).find('ul').toggle();
            // $(this).toggleClass('show');
        })
        this.element.on('click', '.month li', function(){
            let value = $(this).attr('value');
            that.month = value;
            that.getCalendar();
            that.element.trigger({
                type: 'changeMonth',
                month: parseInt(that.month)+1,
                year: parseInt(that.year)
            })
        })
        this.element.on('click', '.enabled', function(){
            that.element.trigger({
                type: 'changeDate',
                date: $(this).html(),
                month: parseInt(that.month)+1,
                year: parseInt(that.year)
            })
        })
    }
}

$.fn.huicalendar = function(options, operate){
    if(operate == 'update'){
        let hui = $(this).data('huicalendar');
        hui.enabledDay = options.enabledDay;
        hui.viewDay = options.viewDay || new Date();
        hui.year = hui.viewDay.getFullYear();
        hui.month = hui.viewDay.getMonth();
        hui.getCalendar();
    } else {
        let hui = new huicalendar(this, options);
        $(this).data('huicalendar', hui);
    }
    return this;
}

//Déclaration d'un tableau vide pour insérer les jours non dipsonibles à la réservation
let notAvailableDaysArray = []
//Fonction pour attribuer des jours de réservation en fonction du mois
function availableDays(currentMonth) {
    switch (currentMonth) {
        case 1:
            notAvailableDaysArray = []
            notAvailableDaysArray.push(1);
            break;
        case 2:
            notAvailableDaysArray = []
            notAvailableDaysArray.push(1,2);
            break;
        case 3:
            notAvailableDaysArray = []
            notAvailableDaysArray.push(1,2,3);
            break;
        case 4:
            notAvailableDaysArray = []
            notAvailableDaysArray.push(1,2,3,4);
            break;
        case 5:
            notAvailableDaysArray = []
            notAvailableDaysArray.push(1,2,3,4,5);
            break;
        case 6:
            notAvailableDaysArray = []
            notAvailableDaysArray.push(1,2,3,4,5,6);
            break;
        case 7:
            notAvailableDaysArray = []
            notAvailableDaysArray.push(1,2,3,4,5,6,7);
            break;
        case 8:
            notAvailableDaysArray = []
            notAvailableDaysArray.push(1,2,3,4,5,6,7,8);
            break;
        case 9:
            notAvailableDaysArray = []
            notAvailableDaysArray.push(1,2,3,4,5,6,7,8,9);
            break;
        case 10:
            notAvailableDaysArray = []
            notAvailableDaysArray.push(1,2,3,4,5,6,7,8,9,10);
            break;
        case 11:
            notAvailableDaysArray = []
            notAvailableDaysArray.push(1,2,3,4,5,6,7,8,9,10,11);
            break;
        case 12:
            notAvailableDaysArray = []
            notAvailableDaysArray.push(1,2,3,4,5,6,7,8,9,10,11,12);
            break;
        default:
            notAvailableDaysArray = []
    }
}
//Init de la fonction "paramètre à insérer en PHP"
availableDays(7)

//Init du calendrier
$('.container-calendar-booking').huicalendar({
    enabledDay: notAvailableDaysArray,

//Changement de mois en cliquant sur les flèches
}).on('changeMonth', function(e){
    availableDays(e.month)
    $(this).huicalendar({
        enabledDay: notAvailableDaysArray,
        viewDay: new Date(e.year+'/'+e.month+'/01')
    }, 'update')
})