$(document).ready(() => {
    $('.big-carousel-home')
        .on("init", function(){
            $.each($(this).find(".item-carousel"), (index, value) => {
                $(value).addClass("loaded");
            })
        })
        .slick({
            slidesToScroll: 1,
            dots: true,
            autoplay: true,
            autoplaySpeed: 5000,
        });
    $('.carousel-villas').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        arrows: true
    });
    $('.carousel-comment-home').slick({
        slidesToScroll: 1,
        dots: false
    });
    $('.carousel-real-estate').slick({
        slidesToShow: 1,
        slidesToScroll: 1
    });
    $('.container-calendar').slick({
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: true,
        responsive: [{
            breakpoint: 991,
            settings: {
                slidesToShow: 1
            }
        }]
    });
    $('.carousel-detail-villa-rental')
        .on("init", function(){
            $.each($(this).find(".slick-slide"), (index, value) => {
                $(value).addClass("loaded");
            })
        }).slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
    });
    $('.slider-nav').slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        asNavFor: '.carousel-detail-villa-rental',
        dots: false,
        centerMode: true,
        focusOnSelect: true,
        responsive: [{
            breakpoint: 750,
            settings: {
                slidesToShow: 3
            }
        }, {
            breakpoint: 500,
            settings: {
                slidesToShow: 2
            }
        }]
    });
});
